.content {
  padding: 0 !important;
}
.shipment {
  background-color: white;
  position: relative;
  overflow: hidden;
  padding: 8px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  > h3,
  h1,
  p {
    margin: 0;
  }
  .dataNotPresent {
    color: #99a1b7;
  }
  .btnGroup {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .iconWrapper {
    font-weight: 400;
    line-height: 1.5;
    padding: 0px 4px;
    border-radius: 4px;
    height: max-content;
    cursor: pointer;
    &:hover {
      background-color: #2060ea;
      color: white;
      svg path {
        fill: white;
      }
    }
  }
  .btnGroup {
    .iconWrapper {
      padding-bottom: 2px;
    }
  }
  .confirmDeletion {
    display: flex;
    gap: 4px;
    align-items: center;
    background-color: rgb(240, 240, 240);
    padding: 0 4px;
    border: 4px;
    margin: 8px 0;
    font-size: 13px;
    font-weight: 400;
    width: max-content;

    input {
      cursor: pointer;
    }
  }

  > .options {
    background-color: white;
    padding-top: 8px;
    padding-bottom: 16px;
    z-index: 10;
    top: 74px;
    display: flex;
    justify-content: space-between;
    ul {
      padding: 0;
      margin: 0;
    }

    .activeState {
      color: #2060ea !important;
    }
    .isAppliedRule {
      color: var(--Green-500, #14804a) !important;
    }
    > .leftSide {
      height: 32px;
      color: rgba(0, 0, 0, 0.25);

      > ul {
        list-style: none;
        display: flex;
        gap: 16px;
        > .tableButton {
          padding: 4px 15px;
          background-color: #f7f8f9;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          cursor: pointer;
          border-radius: 4px;

          > select {
            font-weight: 500;
            border: none;
            background-color: transparent;
            color: #b9b9c6;
          }
        }
      }
    }

    > .rightSide {
      display: flex;
      gap: 16px;
      align-items: center;

      .milestoneBtn {
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0.42px;
        color: var(--GREY, #4a4a68);
        border: 1px solid transparent;
        border-radius: 4px;
        width: max-content;
        position: relative;
        cursor: pointer;
        padding: 4px 14px 4px 15px;
      }

      .activeMilestone {
        color: #2060ea;
        background-color: var(--MIST, #f7f8f9);
      }

      > .selectOptions {
        display: flex;
        align-items: center;
        height: 18px;
        color: #4a4a68;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        > .selectBox {
          color: var(--GREY, #4a4a68);
          font-weight: 500;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.42px;

          > .selectTitle {
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            flex-shrink: 0;
            border: 1px solid transparent;
            border-radius: 4px;
            width: max-content;
            position: relative;
            cursor: pointer;
            padding: 4px 14px 4px 15px;
          }
          .optionsOfSelect {
            position: absolute;
            z-index: 5;
            top: 1rem;
            left: 0;
            display: flex;
            width: 210px;
            padding: 4px 0px;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 4px;
            border: 1px solid var(--PEARL, #ecf1f4);
            background-color: var(--Conditional-pop-over, #fff);

            /* drop-shadow/0.12+0.8+0.5 */
            box-shadow:
              0px 3px 6px -4px rgba(0, 0, 0, 0.12),
              0px 6px 16px 0px rgba(0, 0, 0, 0.08),
              0px 9px 28px 8px rgba(0, 0, 0, 0.05);

            > .listOfView {
              /* padding-left: 5px; */
              width: 100%;
              p {
                pointer-events: none;
                cursor: not-allowed;
                padding-left: 12px;
                color: var(--DARK-GREY, #0e0e2c);
                font-weight: 500;
                line-height: 22px;
              }
            }
            .optionItem {
              padding: 5px 12px;
              color: var(--DARK-GREY, #0e0e2c);
              font-weight: 400;
              line-height: 22px;
              gap: 8px;
              display: flex;
              align-self: stretch;
              align-items: center;
              justify-content: space-between;
              min-width: max-content;

              &:hover {
                color: #1249bf;
                cursor: pointer;
              }
            }
            .listOfView > .optionItem {
              padding-left: 20px;
            }
            > .editView {
              color: var(--BLUE, #2060ea);
            }
          }

          .activeSelectTitle {
            color: #2060ea;
            background-color: var(--MIST, #f7f8f9);
          }
        }

        select {
          border: none;
        }

        > select > option > span {
          font-size: 12px;
          line-height: 14px;
          color: #0e0e2c;
        }
      }

      > .viewIcon {
        width: 80px;
        height: 32px;
        display: flex;

        > .boardIcon {
          border: 1px solid #c5c7d0;
          border-radius: 0px 4px 4px 0px;
          width: 40px;
        }
        > .listIcon {
          border: 1px solid #c5c7d0;
          border-radius: 4px 0px 0px 4px;
          width: 40px;
        }
        > .activeIcon {
          border-color: var(--DARK-BLUE, #1249bf);
          background-color: var(--SKY, #cce5ff);
        }
      }
      > .menuIcon {
        cursor: pointer;
      }
    }
  }

  .createView {
    position: absolute;
    background-color: white;
    left: 0;
    right: 0;
    top: 1rem;
    z-index: 11;
    width: 35vw;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-family: Inter;
    margin: 5vh auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    border: 1px solid var(--PEARL, #ecf1f4);
    background-color: white;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border-radius: 4px;

    > header {
      display: flex;
      justify-content: space-between;
      border-radius: 4px 4px 0px 0px;
      border-bottom: 1px solid #e9edf5;
      background: #f9fafd;
      padding: 16px 16px 8px;
      font-weight: 600;
      line-height: 24px;

      svg {
        cursor: pointer;
      }
    }
    > main {
      display: flex;
      flex-direction: column;
      height: 75vh;

      > label {
        margin: 16px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        > span {
          display: flex;
          flex-direction: column;
          color: var(--GREY, #4a4a68);
          height: 22px;
          font-feature-settings:
            "liga" off,
            "clig" off;
          line-height: 20px; /* 166.667% */
          margin-bottom: 4px;
        }

        > input {
          height: 24px;
          padding: 8px 4px 8px 12px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 22px;
          border-radius: 4px;
          border: 1px solid var(--LIGHT-ASH, #b9b9c6);
          color: var(--DARK-GREY, #0e0e2c);

          &::placeholder {
            color: var(--LIGHT-ASH, #b9b9c6);
          }
        }
      }
      > .detailsOfView {
        display: flex;
        margin: 0 16px;

        .selectBox {
          font-weight: 300;
          border-radius: 4px;
          border: 1px solid var(--LIGHT-ASH, #b9b9c6);
          width: 100%;
        }
        > .leftSide {
          display: flex;
          flex-direction: column;
          width: 50%;
          font-weight: 400;
          border-right: 1px solid #e9edf5;
          gap: 16px;
          height: 65vh;
          overflow-y: auto;
          > .searchBox {
            display: flex;
            height: 24px;
            padding: 4px 4px 4px 12px;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            flex-shrink: 0;
            font-size: 12px;
            border-radius: 4px;
            border: 1px solid var(--Light---ui-border-color, #c5c7d0);
            background-color: #fff;

            > input {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              height: 22px;
              border: none;
              outline: none;

              &::placeholder {
                color: var(--LIGHT-ASH, #b9b9c6);
              }
            }
            svg {
              width: 24px;
              cursor: pointer;
            }
          }
          > .availableColumn {
            display: flex;
            flex-direction: column;

            > p {
              color: var(--GREY, #4a4a68);
              font-size: 10px;
              margin-left: 3px;
              margin-bottom: 0;
            }

            div > label {
              display: flex;
              padding: 5px 12px;
              align-items: center;
              gap: 8px;

              > input {
                border-radius: 2px;
                border: 1px solid var(--LIGHT-GREY, #8c8ca1);
              }
            }
          }
        }

        > .rightSide {
          width: 50%;
          margin: 0 16px;
          height: 65vh;
          overflow-y: auto;

          > p {
            color: var(--GREY, #4a4a68);
            font-size: 10px;
            margin-left: 12px;
            margin-bottom: 0;
          }
          > .selectedColumns {
            > .item {
              display: flex;
              padding: 5px 12px;
              align-items: center;
              gap: 8px;
            }
          }
        }
      }
    }

    > footer {
      height: 48px;
      display: flex;
      justify-content: end;
      padding: 8px 16px;
      gap: 16px;

      > button {
        height: 32px;
        padding: 4px 15px;
        border-radius: 4px;
        border: 1px solid transparent;
        color: var(--GREY, #4a4a68);
        background-color: #f7f8f9;

        &:disabled {
          background-color: var(--MIST, #f7f8f9);
          color: var(--character-disabled-placeholder-25, rgba(0, 0, 0, 0.25));
        }
      }
      > .createViewBtn {
        background: var(--BLUE, #2060ea);
        color: var(--White, #fff);
      }
    }
  }

  .listView {
    overflow-x: auto;
    flex: 1;

    table,
    thead,
    tbody {
      width: 100%;
      font-size: 14px;
    }
    > table {
      min-width: max-content;
      height: 100%;

      > thead > tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 34px;

        > th {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px 10px;
          color: #4a4a68;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;

          svg {
            cursor: pointer;
          }
        }
        > .checkBox {
          padding: 8px 10px 8px 20px;

          > input {
            width: 16px;
            height: 16px;
          }
        }
        .seriesName {
          width: 48px;
        }
        .projectName {
          padding: 8px 10px 8px 22px;
          min-width: 200px;
          position: relative;

          > span {
            display: flex;
            padding: 0px 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 100px;
            background: var(--Peach, #f3d9da);
            cursor: pointer;
          }
        }
        .statusHeader {
          width: 11vw;
        }
        .estimatedDate {
          width: 11rem;
        }
        .userPic {
          width: 144px;
        }
        .wareHouse {
          width: 12vw;
        }
        .supplier {
          width: 15vw;
        }
      }
      > tbody {
        overflow-y: auto;
        height: 100%;
        scrollbar-width: thin;
        display: block;
        > tr {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 48px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;

          > td {
            padding: 12px 10px;
          }

          > .checkBox {
            display: flex;
            padding: 8px 10px 8px 20px;

            > input {
              width: 16px;
              height: 16px;
            }
          }

          .projectName {
            min-width: 200px;
            padding-left: 22px;
            color: #1249bf;
            cursor: pointer;
            a {
              color: #1249bf;
            }
          }

          .seriesName {
            width: 48px;
          }
          .status {
            width: 11vw;
            font-size: 12px;
            line-height: 18px;

            span {
              display: flex;
              align-items: center;
              gap: 6px;
              background-color: #e1fcef;
              padding: 0 8px;
              width: max-content;

              .statusBox {
                width: 6px;
                height: 6px;
                background-color: #38a06c;
                border-radius: 2px;
              }
            }
          }
          .estimatedDate {
            width: 11rem;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 6px;
          }
          .userPic {
            width: 144px;
            font-weight: 400;
          }

          .wareHouse {
            font-weight: 400;
            width: 12vw;
          }
          .supplier {
            width: 15vw;
          }
        }
      }
    }
  }

  > .boardView {
    display: flex;
    width: 100%;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    gap: 24px;
    height: 90vh;
    overflow-x: scroll;
    position: relative;

    > .board {
      /* position: relative; */
      padding: 12px 8px 12px 16px;
      min-width: 24%;
      background-color: var(--MIST, #f7f8f9);
      height: 100%;
      border-radius: 12px 12px 0px 0;
      display: flex;
      flex-direction: column;

      .colorBox {
        line-height: 18px;
        font-weight: 500;
        color: var(--Green-500, #14804a);
        background-color: #e1fcef;
        border-radius: 16px;
        font-size: 12px;
        letter-spacing: 0.36px;
        display: flex;
        padding: 1px 8px;
        align-items: center;
        gap: 6px;
        flex-shrink: 0;

        > .tickBox {
          width: 6px;
          height: 6px;
          border-radius: 2px;
          background-color: #38a06c;
        }
      }
      .green {
        color: #14804a;
        background-color: #e1fcef;
        > .tickBox {
          background-color: #38a06c;
        }
      }
      .orange {
        color: #aa5b00;
        background-color: #fcf2e6;
        > .tickBox {
          background-color: #c97a20;
        }
      }
      .red {
        color: #cf324a;
        background-color: #ffedef;
        > .tickBox {
          background-color: #cf324a;
        }
      }
      .sky {
        color: #262641;
        background-color: #cce5ff;
        > .tickBox {
          background-color: #262641;
        }
      }

      .processing {
        color: #1677ff;
        background-color: #e6f4ff;
        > .tickBox {
          background-color: #1677ff;
        }
      }
      .success {
        color: #52c41a;
        background-color: #f6ffed;
        > .tickBox {
          background-color: #52c41a;
        }
      }
      .warning {
        color: #faad14;
        background-color: #fffbe6;
        > .tickBox {
          background-color: #faad14;
        }
      }
      .magenta {
        color: #c41d7f;
        background-color: #fff0f6;
        > .tickBox {
          background-color: #c41d7f;
        }
      }
      .volcano {
        color: #d4380d;
        background-color: #fff2e8;
        > .tickBox {
          background-color: #d4380d;
        }
      }
      .cyan {
        color: #08979c;
        background-color: #e6fffb;
        > .tickBox {
          background-color: #08979c;
        }
      }
      .geekblue {
        color: #1d39c4;
        background-color: #f0f5ff;
        > .tickBox {
          background-color: #1d39c4;
        }
      }
      .purple {
        color: #531dab;
        background-color: #f9f0ff;
        > .tickBox {
          background-color: #531dab;
        }
      }

      > .boardHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;
        margin-bottom: 8px;

        > h3,
        p {
          margin: 0;
        }
        > .countNo {
          color: var(--GREY, #4a4a68);
          font-size: 16px;
          font-weight: 700;
          line-height: 22px; /* 137.5% */
          padding-right: 12px;
        }
      }
      > .tilesContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow-y: scroll;
        height: 100%;

        > .boardTile {
          display: flex;
          flex-direction: column;
          background-color: #fcfdfe;
          padding: 8px 16px;
          border-radius: 8px;
          border: 1px solid var(--MIST, #f7f8f9);
          background-color: var(--CLOUD, #fcfdfe);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
          gap: 8px;

          > .tileHeader {
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: space-between;

            > .left {
              display: flex;
              gap: 8px;
              > .checkBox {
                width: 16px;
                height: 16px;
                filter: drop-shadow(0px 0px 0px rgba(70, 79, 96, 0.16))
                  drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
              }
              > a {
                color: #2060ea;
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
                letter-spacing: 0.28px;
              }
            }
            .iconInBoat {
              border-radius: 4px;
              border-bottom: 1px solid #1249bf;
              &:hover {
                background-color: #2060ea;
                border-color: transparent;
              }
            }
          }
          > .orderInfo {
            display: flex;
            flex-direction: column;
            gap: 6px;
            color: var(--Dark-Grey, #5a5a65);
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.1px;
            .colorBoxWidth {
              width: max-content;
            }
            > p {
              margin: 0;
            }
            > .supplier {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          > .eta {
            color: #4d4d4d;
            padding: 4px 10px;
            border-radius: 8px;
            background-color: #ecf1f4;
            line-height: normal;
            width: max-content;
            letter-spacing: -0.4px;
          }
        }
      }
    }
  }

  > .selectBox {
    color: var(--GREY, #4a4a68);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.42px;

    > .selectTitle {
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex-shrink: 0;
      border: 1px solid transparent;
      border-radius: 4px;
      width: max-content;
      position: relative;
      cursor: pointer;
      padding: 4px 14px 4px 15px;

      > .optionsOfSelect {
        position: absolute;
        z-index: 5;
        top: 3rem;
        left: 0;
        display: flex;
        width: 210px;
        padding: 4px 0px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 4px;
        border: 1px solid var(--PEARL, #ecf1f4);
        background-color: var(--Conditional-pop-over, #fff);

        /* drop-shadow/0.12+0.8+0.5 */
        box-shadow:
          0px 3px 6px -4px rgba(0, 0, 0, 0.12),
          0px 6px 16px 0px rgba(0, 0, 0, 0.08),
          0px 9px 28px 8px rgba(0, 0, 0, 0.05);

        > .optionItem {
          display: flex;
          padding: 5px 12px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          color: var(--DARK-GREY, #0e0e2c);
          font-weight: 400;
          line-height: 22px;
          justify-content: space-between;

          &:hover {
            color: #1249bf;
            cursor: pointer;
          }
        }
        > .editView {
          color: var(--BLUE, #2060ea);
        }
      }
    }

    .activeSelectTitle {
      color: #2060ea;
      background-color: var(--MIST, #f7f8f9);
    }
  }

  @media (min-width: 1400px) {
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container,
    #kt_content_container {
      padding: 0;
      padding-left: 20px;
    }
  }
}
